import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/pic01.jpg'

const Thanks = (props) => (
  <Layout>
    <Helmet>
      <title>Thanks</title>
      <meta name="description" content="success Page" />
    </Helmet>

    <div id="main">
      <section id="one" className="tiles">
        <article >
          <header className="major">
            <h3>Success</h3>
            <p>Thanks for contacting us.  Someone should be with you shortly!</p>
          </header>
        </article>
        <article >
          <header className="major">
            <h3 hidden>Success</h3>
            <p hidden>Thanks for contacting us.  Someone should be with you shortly!</p>
          </header>
        </article>
        <article >
          <header className="major">
            <h3 hidden>Success</h3>
            <p hidden>Thanks for contacting us.  Someone should be with you shortly!</p>
          </header>
        </article>
        <article >
          <header className="major">
            <h3 hidden>Success</h3>
            <p hidden>Thanks for contacting us.  Someone should be with you shortly!</p>
          </header>
        </article>
      </section>
    </div>

  </Layout>
)

export default Thanks